import clsx from "clsx";
import { FunctionComponent as FC } from "preact";
import { useEffect } from "preact/hooks";
import FantasyMatchups from "./components/FantasyMatchups";
import FantasyScoreBoard from "./components/FantasyScoreBoard";
import GameComponent from "./components/Game";
import Settings from "./components/Settings";
import { useGamesStore } from "./data/games";
import { useSidebarStore } from "./data/sidebar";
import { GameStatus } from "./data/status";
import { Game, useStreamingGames } from "./data/streams";
import { useActive } from "./data/switcher";
import styles from "./App.module.css";

const App: FC = () => {
    const currentGames = useStreamingGames();
    const { playingGames } = useGamesStore();
    const activeGame = useActive();
    const { isOpen } = useSidebarStore();

    const filteredGames = currentGames.filter(
        (g): g is { game: Game; status: GameStatus } =>
            g.game?.streamId ? playingGames.has(g.game.streamId) : false,
    );
    const activeGameIndex = filteredGames.findIndex(
        ({ game }) => game?.streamId === activeGame,
    );
    const gamesLength =
        activeGameIndex >= 0 ? filteredGames.length - 1 : filteredGames.length;

    useEffect(() => {
        if (activeGame !== null) {
            window.postMessage({
                action: "mute-streams",
                streamId: activeGame,
            });
        }
    }, [activeGame]);

    return (
        <main className={styles.main}>
            <div className={clsx(styles.matchup, { [styles.isOpen]: isOpen })}>
                <FantasyMatchups />
                <FantasyScoreBoard />
                <Settings />
            </div>
            <div className={styles.games}>
                {filteredGames.map(({ game }, index) => (
                    <GameComponent
                        key={game.streamId}
                        game={game}
                        index={
                            activeGameIndex >= 0 && index >= activeGameIndex
                                ? index - 1
                                : index
                        }
                        length={gamesLength}
                        isActive={game.streamId === activeGame}
                    />
                ))}
            </div>
        </main>
    );
};

export default App;
