import clsx from "clsx";
import { FunctionComponent as FC } from "preact";
import { FantasyPlayerWithChanges } from "../data/fantasy";
import { useStatus } from "../data/status";
import styles from "./FantasyPlayer.module.css";

type FantasyPlayerProps = {
    player: FantasyPlayerWithChanges;
};

const gameTimeFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
});

const FantasyPlayerComponent: FC<FantasyPlayerProps> = ({ player }) => {
    const status = useStatus(player.team);
    let homeAway = undefined;
    let gameTime = undefined;
    if (status) {
        if (player.position !== "DEF") {
            homeAway = status.homeShort === player.team ? "home" : "away";
        } else {
            homeAway = status.homeShort === player.team ? "away" : "home";
        }

        if (status.status === "SCHEDULED") {
            const time = gameTimeFormatter.format(status.startTime);
            gameTime =
                homeAway === "home"
                    ? `${time} v ${status.awayShort}`
                    : `${time} @ ${status.homeShort}`;
        }
    }

    return (
        <div
            className={clsx(styles.fantasyPlayer, {
                [styles.isPlaying]:
                    status?.status === "ONGOING" ||
                    status?.status === "HALF_TIME",
                [styles.isFinished]: status?.status === "FINISHED",
                [styles.onField]: status && status.ball === homeAway,
                [styles.inRedZone]: status?.redzone,
                [styles.isChanged]: player.changed,
            })}
        >
            <div className={styles.fantasyPlayerMeta}>
                <div className={styles.fantasyPlayerHeader}>
                    <div
                        className={styles.fantasyPlayerName}
                        title={player.name}
                    >
                        {player.name}
                    </div>
                    <div className={styles.fantasyPlayerInformation}>
                        {player.position} - {player.team}
                    </div>
                </div>
                <div className={styles.fantasyPlayerStats} title={player.stats}>
                    {gameTime ? gameTime : player.stats || "0 Yds"}
                </div>
            </div>
            <div className={styles.fantasyPlayerPoints}>
                {player.points.toFixed(2)}
            </div>
        </div>
    );
};

export default FantasyPlayerComponent;
