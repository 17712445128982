import clsx from "clsx";
import { Menu, X } from "lucide-preact";
import { FunctionComponent as FC } from "preact";
import { useFantasyMatchupQuery, useFantasyStore } from "../data/fantasy";
import { useGamesStore } from "../data/games";
import { useSidebarStore } from "../data/sidebar";
import Button from "./Button";
import FantasyMatchupTable from "./FantasyMatchupTable";
import styles from "./FantasyMatchups.module.css";

const FantasyMatchups: FC = () => {
    const { setAuto } = useGamesStore();
    const { lastSelectedTeamId, selectedTeams, selectTeam, openTeam } =
        useFantasyStore();
    const { isOpen, setOpen } = useSidebarStore();
    const { data, loading } = useFantasyMatchupQuery();

    if (!data && loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div
                className={clsx(styles.fantasyMatchupButton, {
                    [styles.isInside]: isOpen,
                })}
            >
                <Button onClick={() => setOpen(!isOpen)} title="Open sidebar">
                    {isOpen ? <X /> : <Menu />}
                </Button>
            </div>
            {data && (
                <div className={styles.fantasyMatchupContainer}>
                    <h2 className={styles.fantasyMatchupTitle}>
                        Fantasy Matchup
                    </h2>
                    {data.map((matchup) => (
                        <FantasyMatchupTable
                            matchup={matchup}
                            selectedTeams={selectedTeams}
                            lastSelectedTeamId={lastSelectedTeamId}
                            onOpen={(id) => openTeam(id)}
                            onSelect={(id) => {
                                selectTeam(id);
                                setAuto(true);
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FantasyMatchups;
