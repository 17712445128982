import { FunctionComponent as FC } from "preact";
import { useDelay } from "../data/delay";
import styles from "./Settings.module.css";

const Settings: FC = () => {
    const { delay, setDelay } = useDelay();

    return (
        <div class={styles.settings}>
            <span>Settings:</span>
            <div>
                delay{" "}
                <input
                    type="number"
                    className={styles.settingsDelay}
                    value={delay}
                    min="0"
                    onChange={(event) => {
                        setDelay(
                            Number.parseInt(
                                (event.target as HTMLInputElement).value,
                            ),
                        );
                    }}
                />
                s
            </div>
        </div>
    );
};

export default Settings;
