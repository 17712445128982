import clsx from "clsx";
import { ChevronsUp } from "lucide-preact";
import { FunctionComponent } from "preact";
import Button from "./Button";
import styles from "./BoostAction.module.css";

type BoostActionProps = {
    boost?: number;
    setBoost: (b: number) => void;
};

const BoostAction: FunctionComponent<BoostActionProps> = ({
    boost,
    setBoost,
}) => {
    if (boost === undefined) {
        return (
            <Button
                className={styles.boostActionButton}
                title="Boost game"
                onClick={(ev) => {
                    ev.stopPropagation();
                    setBoost(3);
                }}
            >
                <ChevronsUp />
            </Button>
        );
    }

    return (
        <input
            className={clsx(styles.boostActionInput)}
            type="number"
            title="Boost game"
            value={boost ?? 0}
            onClick={(ev) => ev.stopPropagation()}
            onChange={(ev) => {
                ev.stopPropagation();
                setBoost(+(ev.target as HTMLInputElement).value);
            }}
        />
    );
};

export default BoostAction;
