import { persistentAtom } from "@nanostores/persistent";
import { useStore } from "@nanostores/preact";
import { CommonSettings, nanoquery } from "@nanostores/query";

export const [createFetcherStore, createMutatorStore] = nanoquery({
    async fetcher(...keys) {
        return fetch(keys.join("")).then((r) => r.json());
    },
});

const sleep = (s: number) =>
    new Promise((resolve) => setTimeout(resolve, s * 1000));

const delayStore = persistentAtom<number>("DELAY_STORE", 0, {
    encode: JSON.stringify,
    decode: JSON.parse,
});
const setDelay = (s: number) => {
    delayStore.set(s);
};

export const useDelay = () => {
    const delay = useStore(delayStore);

    return { delay, setDelay };
};

export const createDelayedQuery = <T>(
    key: string,
    action: (t: T | undefined) => Promise<T>,
    options: CommonSettings<T>,
) => {
    const fetcher = createFetcherStore<T>(key, {
        fetcher: async () => {
            const previousData = fetcher.get();
            const data = await action(previousData.data);
            if (previousData.data) {
                await sleep(delayStore.get());
            }
            return data;
        },
        ...options,
    });
    return fetcher;
};
