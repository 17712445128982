import { FunctionComponent as FC } from "preact";

type IframeProps = {
    id: string;
};

const Iframe: FC<IframeProps> = ({ id }) => {
    return (
        <iframe
            width="100%"
            height="100%"
            allowFullScreen
            frameBorder={0}
            src={`https://freelivestreamhd.com/papa.php?hd=${id}`}
        ></iframe>
    );
};

export default Iframe;
