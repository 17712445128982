import { persistentAtom } from "@nanostores/persistent";
import { useStore } from "@nanostores/preact";
import { createDelayedQuery } from "./delay";

export type FantasyPlayer = {
    name: string;
    position: string;
    team: string;
    points: number;
    stats: string;
};

export type FantasyTeam = {
    id: string;
    name: string;
    players: FantasyPlayer[];
    subs: FantasyPlayer[];
    points: number;
};

export type FantasyMatchup = {
    team: FantasyTeam;
    opposition: FantasyTeam;
};

export type FantasyData = FantasyMatchup[];

export type FantasyPlayerWithChanges = FantasyPlayer & {
    changed?: boolean;
};

export type FantasyTeamWithChanges = FantasyTeam & {
    players: FantasyPlayerWithChanges[];
    subs: FantasyPlayerWithChanges[];
};

export type FantasyMatchupWithChanges = {
    team: FantasyTeamWithChanges;
    opposition: FantasyTeamWithChanges;
};

export type FantasyDataWithChanges = FantasyMatchupWithChanges[];

export type FantasyStore = {
    lastSelectedTeamId: string | undefined;
    selectedTeams: string[];
};

const matchupStore = persistentAtom<FantasyStore>(
    "MATCHUP_STORE",
    {
        lastSelectedTeamId: undefined,
        selectedTeams: [],
    },
    {
        encode: JSON.stringify,
        decode: JSON.parse,
    },
);
const selectTeam = (id: string) => {
    const { lastSelectedTeamId, selectedTeams } = matchupStore.get();
    matchupStore.set(
        selectedTeams.includes(id)
            ? {
                  lastSelectedTeamId,
                  selectedTeams: selectedTeams.filter((m) => m !== id),
              }
            : {
                  lastSelectedTeamId: id,
                  selectedTeams: selectedTeams.concat(id),
              },
    );
};

const openTeam = (id: string) => {
    const { selectedTeams } = matchupStore.get();
    matchupStore.set({ lastSelectedTeamId: id, selectedTeams });
};

export const useFantasyStore = () => {
    const { lastSelectedTeamId, selectedTeams } = useStore(matchupStore);

    return { lastSelectedTeamId, selectedTeams, selectTeam, openTeam };
};

const FANTASY_URL = import.meta.env.VITE_FANTASY_URL ?? "http://localhost:3000";

const zipWith = <T>(
    arr1: T[],
    arr2: T[],
    mapper: (item1: T, item2: T) => T,
) => {
    const minLength = Math.min(arr1.length, arr2.length);
    const result: T[] = new Array(minLength);
    for (let i = 0; i < minLength; ++i) {
        result[i] = mapper(arr1[i], arr2[i]);
    }
    return result;
};

const matchupFetcher = createDelayedQuery<FantasyDataWithChanges>(
    "fantasyMatchup",
    async (previousMatchups) => {
        const matchups: FantasyData = await fetch(
            `${FANTASY_URL}/matchup`,
        ).then((data) => data.json());

        if (previousMatchups) {
            return zipWith(
                matchups,
                previousMatchups,
                (matchup, previousMatchup) => {
                    return {
                        team: {
                            ...matchup.team,
                            players: zipWith(
                                matchup.team.players,
                                previousMatchup.team.players,
                                (player, previousPlayer) => {
                                    return {
                                        ...player,
                                        changed:
                                            player.stats !==
                                            previousPlayer.stats,
                                    };
                                },
                            ),
                        },
                        opposition: {
                            ...matchup.opposition,
                            players: zipWith(
                                matchup.opposition.players,
                                previousMatchup.opposition.players,
                                (player, previousPlayer) => {
                                    return {
                                        ...player,
                                        changed:
                                            player.stats !==
                                            previousPlayer.stats,
                                    };
                                },
                            ),
                        },
                    };
                },
            );
        }

        return matchups;
    },
    {
        revalidateInterval: 10000,
    },
);

export const useFantasyMatchupQuery = () => {
    const query = useStore(matchupFetcher);

    return query;
};

export const useMatchupPlayers = () => {
    const { selectedTeams } = useFantasyStore();
    const query = useFantasyMatchupQuery();

    return query.data?.flatMap((matchup) => {
        let players: FantasyPlayer[] = [];
        if (selectedTeams.includes(matchup.team.id)) {
            players = players.concat(matchup.team.players);
        }
        if (selectedTeams.includes(matchup.opposition.id)) {
            players = players.concat(matchup.opposition.players);
        }
        return players;
    });
};
