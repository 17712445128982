import { useEffect, useState } from "preact/hooks";

const AD_SECONDS = 80 * 1000;

const useAdTimeout = () => {
    const [adTimeout, setAdTimeout] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setAdTimeout(new Date(Date.now() - AD_SECONDS));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return adTimeout;
};

export default useAdTimeout;
