import { FantasyMatchup } from "../data/fantasy";
import FantasyMatchupTeam from "./FantasyMatchupTeam";
import styles from "./FantasyMatchupTable.module.css";

type FantasyMatchupTableProps = {
    matchup: FantasyMatchup;
    selectedTeams: string[];
    lastSelectedTeamId: string | undefined;
    onSelect: (id: string) => void;
    onOpen: (id: string) => void;
};

const FantasyMatchupTable = ({
    matchup,
    selectedTeams,
    lastSelectedTeamId,
    onSelect,
    onOpen,
}: FantasyMatchupTableProps) => {
    return (
        <div className={styles.fantasyMatchupTable}>
            <FantasyMatchupTeam
                team={matchup.team}
                isOpen={
                    matchup.team.id === lastSelectedTeamId ||
                    matchup.opposition.id === lastSelectedTeamId
                }
                isSelected={selectedTeams.includes(matchup.team.id)}
                onSelect={() => onSelect(matchup.team.id)}
                onOpen={() => onOpen(matchup.team.id)}
            />
            <FantasyMatchupTeam
                team={matchup.opposition}
                isOpen={
                    matchup.team.id === lastSelectedTeamId ||
                    matchup.opposition.id === lastSelectedTeamId
                }
                isSelected={selectedTeams.includes(matchup.opposition.id)}
                onSelect={() => onSelect(matchup.opposition.id)}
                onOpen={() => onOpen(matchup.opposition.id)}
            />
        </div>
    );
};

export default FantasyMatchupTable;
