import { persistentAtom } from "@nanostores/persistent";
import { useStore } from "@nanostores/preact";
import useAdTimeout from "./ad";
import { useFantasyStore, useMatchupPlayers } from "./fantasy";
import { useGamesStore } from "./games";
import { GameStatus } from "./status";
import { Game, useStreamingGames } from "./streams";

export type CalculatedGame = {
    game?: Game;
    status: GameStatus;
    points: number;
    homePlayers: number;
    awayPlayers: number;
};

type BoostStore = Record<string, number>;

const boostStore = persistentAtom<BoostStore>(
    "BOOST_STORE",
    {},
    {
        encode: JSON.stringify,
        decode: JSON.parse,
    },
);

const setBoost = (id: string, boost: number) => {
    boostStore.set({
        ...boostStore.get(),
        [id]: boost,
    });
};

export const useBoost = (id: string) => {
    const boosts = useStore(boostStore);

    return [boosts[id], (boost: number) => setBoost(id, boost)] as const;
};

export const usePointCalculator = () => {
    const players = useMatchupPlayers();
    const streamingGames = useStreamingGames();
    const adTimeout = useAdTimeout();
    const boosts = useStore(boostStore);
    const { selectedTeams } = useFantasyStore();
    const teamsCount = selectedTeams.length || 1;

    return streamingGames.map(({ status, game }): CalculatedGame => {
        const boost = boosts[status.eventId];
        const homePlayers =
            players?.filter(
                (player) =>
                    (player.team === status.homeShort &&
                        player.position !== "DEF") ||
                    (player.team === status.awayShort &&
                        player.position === "DEF"),
            ).length ?? 0;
        const awayPlayers =
            players?.filter(
                (player) =>
                    (player.team === status.awayShort &&
                        player.position !== "DEF") ||
                    (player.team === status.homeShort &&
                        player.position === "DEF"),
            ).length ?? 0;

        // If the game is running, we might watch it
        let points = 1;
        // Add boost for every
        if (boost) {
            points += boost * 10;
        }
        // Add points for every player who's on field
        if (status.ball === "home") {
            points += (homePlayers * 10) / teamsCount;
        }
        if (status.ball === "away") {
            points += (awayPlayers * 10) / teamsCount;
        }
        // If the teams are in the red zone it should be more interesting
        if (status.redzone) {
            points *= 10;
        }
        // If the teams are in the last 2 minutes it should be more interesting
        if (status.period === 2 && Number.parseInt(status.clock, 10) <= 2) {
            points *= 2;
        }
        if (status.period === 4 && Number.parseInt(status.clock, 10) <= 2) {
            points *= 4;
        }

        // If there hasn't been a new play since the ad timeout, the game is stopped
        if (status.lastPlayTimestamp < adTimeout) {
            points = 0;
        }

        return {
            status,
            game,
            points,
            homePlayers: homePlayers,
            awayPlayers: awayPlayers,
        };
    });
};

export const useActive = () => {
    const { isAuto, activeGame, playingGames } = useGamesStore();
    const gamesWithPoints = usePointCalculator();

    if (isAuto && gamesWithPoints !== undefined) {
        const bestGame = gamesWithPoints.reduce<CalculatedGame | undefined>(
            (currentBest, status) => {
                const bestPoints = currentBest?.points ?? 0;

                if (
                    status.game?.streamId &&
                    playingGames.has(status.game?.streamId)
                ) {
                    return status.points > bestPoints ? status : currentBest;
                }

                return currentBest;
            },
            undefined,
        );

        if (bestGame?.game) {
            return bestGame.game.streamId;
        }
    }

    return activeGame;
};
