import { atom } from "nanostores";
import { useStore } from "@nanostores/preact";

const sidebarStore = atom(true);

const setOpen = (open: boolean) => {
    sidebarStore.set(open);
};

export const useSidebarStore = () => {
    const isOpen = useStore(sidebarStore);

    return {
        isOpen,
        setOpen,
    };
};
