import { deepMap } from "nanostores";
import { useStore } from "@nanostores/preact";

type GamesStore = {
    playingGames: Set<string>;
    activeGame: string | null;
    isAuto: boolean;
};

const gamesStore = deepMap<GamesStore>({
    playingGames: new Set(),
    activeGame: null,
    isAuto: false,
});

const play = (id: string) => {
    const playingGames = gamesStore.get().playingGames;
    playingGames.add(id);
    gamesStore.setKey("playingGames", playingGames);
};
const stop = (id: string) => {
    const playingGames = gamesStore.get().playingGames;
    playingGames.delete(id);
    gamesStore.setKey("playingGames", playingGames);
};
const setActive = (activeGame: string | null) => {
    gamesStore.setKey("activeGame", activeGame);
    gamesStore.setKey("isAuto", false);
};
const setAuto = (isAuto: boolean) => {
    gamesStore.setKey("isAuto", isAuto);
};

export const useGamesStore = () => {
    const { activeGame, isAuto, playingGames } = useStore(gamesStore);

    return {
        activeGame,
        isAuto,
        playingGames,
        play,
        stop,
        setActive,
        setAuto,
    };
};

