import clsx from "clsx";
import { FunctionComponent } from "preact";
import { type HTMLAttributes } from "preact/compat";
import styles from "./Button.module.css";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {};

const Button: FunctionComponent<ButtonProps> = ({
    children,
    className,
    ...props
}) => {
    return (
        <button className={clsx(styles.button, className)} {...props}>
            {children}
        </button>
    );
};

export default Button;
