import { useStore } from "@nanostores/preact";
import { createDelayedQuery } from "./delay";

type EspnStatus = {
    events: Event[];
};

type Event = {
    id: string;
    uid: string;
    date: string;
    name: string;
    shortName: string;
    season: Season3;
    week: Week2;
    competitions: Competition[];
    links: Link[];
    status: Status2;
    weather?: Weather;
};

type Season3 = {
    year: number;
    type: number;
    slug: string;
};

type Week2 = {
    number: number;
};

type Competition = {
    id: string;
    uid: string;
    date: string;
    attendance: number;
    type: Type2;
    timeValid: boolean;
    neutralSite: boolean;
    conferenceCompetition: boolean;
    playByPlayAvailable: boolean;
    recent: boolean;
    venue: Venue;
    competitors: Competitor[];
    notes: Note[];
    situation?: Situation;
    status: Status;
    broadcasts: Broadcast[];
    leaders: Leader3[];
    format: Format;
    startDate: string;
    broadcast: string;
    geoBroadcasts: GeoBroadcast[];
    tickets?: Ticket[];
    odds?: Odd[];
    headlines?: Headline[];
};

type Type2 = {
    id: string;
    abbreviation: string;
};

type Venue = {
    id: string;
    fullName: string;
    address: Address;
    indoor: boolean;
};

type Address = {
    city: string;
    state?: string;
};

type Competitor = {
    id: string;
    uid: string;
    type: string;
    order: number;
    homeAway: string;
    team: Team;
    score: string;
    linescores?: Linescore[];
    records: Record[];
    leaders?: Leader[];
    winner?: boolean;
};

type Team = {
    id: string;
    uid: string;
    location: string;
    name: string;
    abbreviation: string;
    displayName: string;
    shortDisplayName: string;
    color: string;
    alternateColor: string;
    isActive: boolean;
    venue: Venue2;
    links: Link[];
    logo: string;
};

type Venue2 = {
    id: string;
};

type Linescore = {
    value: number;
};

type Record = {
    name: string;
    abbreviation?: string;
    type: string;
    summary: string;
};

type Leader = {
    name: string;
    displayName: string;
    shortDisplayName: string;
    abbreviation: string;
    leaders: Leader2[];
};

type Leader2 = {
    displayValue: string;
    value: number;
    athlete: Athlete;
    team: Team3;
};

type Athlete = {
    id: string;
    fullName: string;
    displayName: string;
    shortName: string;
    links: Link[];
    headshot: string;
    jersey: string;
    position: Position;
    team: Team2;
    active: boolean;
};

type Position = {
    abbreviation: string;
};

type Team2 = {
    id: string;
};

type Team3 = {
    id: string;
};

type Note = {
    type: string;
    headline: string;
};

type Situation = {
    lastPlay: LastPlay;
    down: number;
    yardLine: number;
    distance: number;
    isRedZone: boolean;
    homeTimeouts: number;
    awayTimeouts: number;
    possession?: string;
    possessionText?: string;
};

type LastPlay = {
    id: string;
    type: Type3;
    text: string;
    scoreValue: number;
    team: Team4;
    probability: Probability;
    drive: Drive;
    start: Start2;
    end: End2;
    statYardage: number;
};

type Type3 = {
    id: string;
    text: string;
    abbreviation: string;
};

type Team4 = {
    id: string;
};

type Probability = {
    tiePercentage: number;
    homeWinPercentage: number;
    awayWinPercentage: number;
    secondsLeft: number;
};

type Drive = {
    description: string;
    start: Start;
    end: End;
    timeElapsed: TimeElapsed;
    result: string;
};

type Start = {
    yardLine: number;
    text: string;
};

type End = {
    yardLine: number;
    text: string;
};

type TimeElapsed = {
    displayValue: string;
};

type Start2 = {
    yardLine: number;
};

type End2 = {
    yardLine: number;
    team: Team5;
};

type Team5 = {
    id: string;
};

type Status = {
    clock: number;
    displayClock: string;
    period: number;
    type: Type4;
    isTBDFlex: boolean;
};

type Type4 = {
    id: string;
    name: string;
    state: string;
    completed: boolean;
    description: string;
    detail: string;
    shortDetail: string;
};

type Broadcast = {
    market: string;
    names: string[];
};

type Leader3 = {
    name: string;
    displayName: string;
    shortDisplayName: string;
    abbreviation: string;
    leaders: Leader4[];
};

type Leader4 = {
    displayValue: string;
    value: number;
    athlete: Athlete2;
    team: Team7;
};

type Athlete2 = {
    id: string;
    fullName: string;
    displayName: string;
    shortName: string;
    links: Link[];
    headshot: string;
    jersey: string;
    position: Position2;
    team: Team6;
    active: boolean;
};

type Position2 = {
    abbreviation: string;
};

type Team6 = {
    id: string;
};

type Team7 = {
    id: string;
};

type Format = {
    regulation: Regulation;
};

type Regulation = {
    periods: number;
};

type GeoBroadcast = {
    type: Type5;
    market: Market;
    media: Media;
    lang: string;
    region: string;
};

type Type5 = {
    id: string;
    shortName: string;
};

type Market = {
    id: string;
    type: string;
};

type Media = {
    shortName: string;
    logo?: string;
    darkLogo?: string;
};

type Ticket = {
    summary: string;
    numberAvailable: number;
    links: Link[];
};

type Odd = {
    provider: Provider;
    details: string;
    overUnder: number;
    spread: number;
    awayTeamOdds: AwayTeamOdds;
    homeTeamOdds: HomeTeamOdds;
    open: Open;
    current: Current;
};

type Provider = {
    id: string;
    name: string;
    priority: number;
};

type AwayTeamOdds = {
    favorite: boolean;
    underdog: boolean;
    team: Team8;
};

type Team8 = {
    id: string;
    uid: string;
    abbreviation: string;
    name: string;
    displayName: string;
    logo: string;
};

type HomeTeamOdds = {
    favorite: boolean;
    underdog: boolean;
    team: Team9;
};

type Team9 = {
    id: string;
    uid: string;
    abbreviation: string;
    name: string;
    displayName: string;
    logo: string;
};

type Open = {
    over: Over;
    under: Under;
    total: Total;
};

type Over = {
    value: number;
    displayValue: string;
    alternateDisplayValue: string;
    decimal: number;
    fraction: string;
    american: string;
};

type Under = {
    value: number;
    displayValue: string;
    alternateDisplayValue: string;
    decimal: number;
    fraction: string;
    american: string;
};

type Total = {
    value: number;
    displayValue: string;
    alternateDisplayValue: string;
    decimal: number;
    fraction: string;
    american: string;
};

type Current = {
    over: Over2;
    under: Under2;
    total: Total2;
};

type Over2 = {
    value: number;
    displayValue: string;
    alternateDisplayValue: string;
    decimal: number;
    fraction: string;
    american: string;
};

type Under2 = {
    value: number;
    displayValue: string;
    alternateDisplayValue: string;
    decimal: number;
    fraction: string;
    american: string;
};

type Total2 = {
    alternateDisplayValue: string;
    american: string;
};

type Headline = {
    type: string;
    description: string;
    shortLinkext: string;
};

type Status2 = {
    clock: number;
    displayClock: string;
    period: number;
    type: Type6;
};

type Type6 = {
    id: string;
    name: string;
    state: string;
    completed: boolean;
    description: string;
    detail: string;
    shortDetail: string;
};

type Weather = {
    displayValue: string;
    temperature: number;
    highTemperature: number;
    conditionId: string;
    link: Link;
};

type Link = {
    language: string;
    rel: string[];
    href: string;
    text: string;
    shortText: string;
    isExternal: boolean;
    isPremium: boolean;
};

type HomeAway = "home" | "away";

type CurrentStatus = "SCHEDULED" | "ONGOING" | "FINISHED" | "HALF_TIME";

export type GameStatus = {
    eventId: string;
    status: CurrentStatus;
    startTime: Date;
    home: string;
    homeShort: string;
    away: string;
    awayShort: string;
    homeLogo: string;
    awayLogo: string;
    homeScore?: number;
    awayScore?: number;
    ball?: HomeAway;
    redzone?: boolean;
    period: number;
    clock: string;
    lastPlayId?: string;
    lastPlayTimestamp: Date;
    endTimestamp?: Date;
};

const AFTER_GAME = 10 * 60 * 1000; // 10 min
const MAX_GAME_TIME = 4 * 60 * 60 * 1000; // 4 hours

const STATUS_KEY = "status";

export const isOngoing = (status: GameStatus) => {
    switch (status.status) {
        case "ONGOING":
        case "HALF_TIME":
            return true;
        case "FINISHED":
            if (status.endTimestamp) {
                return new Date() <= status.endTimestamp;
            }
    }
    return false;
};

const mapShortName = (name: string): string => {
    switch (name) {
        case "WSH":
            return "WAS";
        case "JAG":
            return "JAX";
        default:
            return name;
    }
};

export const mapPeriod = (period: number): string => {
    switch (period) {
        case 1:
            return "1st";
        case 2:
            return "2nd";
        case 3:
            return "3rd";
        case 4:
            return "4th";
        default:
            return "";
    }
};

const statusFetcher = createDelayedQuery<GameStatus[]>(
    STATUS_KEY,
    (previousData) =>
        fetch(
            "https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard",
        )
            .then((data) => data.json() as Promise<EspnStatus>)
            .then((data) => {
                return data.events.map((event): GameStatus => {
                    const previousEvent = previousData?.find(
                        (p) => p.eventId === event.id,
                    );

                    const competition = event.competitions[0];
                    const [home, away] = competition.competitors;
                    return {
                        eventId: event.id,
                        startTime: new Date(event.date),
                        status:
                            competition.status.type.name === "STATUS_SCHEDULED"
                                ? "SCHEDULED"
                                : competition.status.type.name ===
                                    "STATUS_FINAL"
                                  ? "FINISHED"
                                  : competition.status.type.name ===
                                      "STATUS_HALFTIME"
                                    ? "HALF_TIME"
                                    : "ONGOING",
                        home: home.team.displayName,
                        homeShort: mapShortName(home.team.abbreviation),
                        away: away.team.displayName,
                        awayShort: mapShortName(away.team.abbreviation),
                        homeLogo: home.team.logo,
                        awayLogo: away.team.logo,
                        homeScore: Number.parseInt(home.score),
                        awayScore: Number.parseInt(away.score),
                        ball:
                            competition.situation?.possession === home.id
                                ? "home"
                                : competition.situation?.possession === away.id
                                  ? "away"
                                  : undefined,
                        redzone: competition.situation?.isRedZone,
                        period: competition.status.period,
                        clock: competition.status.displayClock,
                        lastPlayId: competition.situation?.lastPlay.id,
                        lastPlayTimestamp:
                            previousEvent?.lastPlayId ===
                            competition.situation?.lastPlay.id
                                ? (previousEvent?.lastPlayTimestamp ??
                                  new Date())
                                : new Date(),
                        // Don't end the game immediately, after it is marked final:
                        // wait 15 minutes unless it has already ended a long time ago
                        endTimestamp:
                            previousEvent?.endTimestamp ??
                            (competition.status.type.name === "STATUS_FINAL" &&
                            new Date(competition.startDate).getTime() +
                                MAX_GAME_TIME >=
                                Date.now()
                                ? new Date(Date.now() + AFTER_GAME)
                                : undefined),
                    };
                });
            }),
    {
        revalidateInterval: 10000,
    },
);

export const useStatuses = () => {
    const query = useStore(statusFetcher);

    return query;
};

export const useStatus = (team: string) => {
    const { data: statuses } = useStatuses();

    return statuses?.find(
        (game) =>
            game.home === team ||
            game.away === team ||
            game.homeShort === team ||
            game.awayShort === team,
    );
};
