import { ArrowDown, ArrowUp, X } from "lucide-preact";
import { FunctionComponent as FC } from "preact";
import { Game } from "../data/streams";
import Button from "./Button";
import styles from "./GameSettings.module.css";

type GameSettingsProps = {
    game: Game;
    isActive: boolean;
    toggleActive: () => void;
    togglePlaying: () => void;
};

const GameSettings: FC<GameSettingsProps> = ({
    isActive,
    toggleActive,
    togglePlaying,
}) => {
    return (
        <div className={styles.gameSettings}>
            <div className={styles.gameSettingsTopButtonContainer}>
                <Button
                    onClick={toggleActive}
                    title={isActive ? "Maximise game" : "Minimise game"}
                >
                    {isActive ? <ArrowDown /> : <ArrowUp />}
                </Button>
                <Button onClick={() => togglePlaying()} title="Open settings">
                    <X />
                </Button>
            </div>
        </div>
    );
};

export default GameSettings;
