import clsx from "clsx";
import { FunctionComponent as FC } from "preact";
import { useGamesStore } from "../data/games";
import { Game } from "../data/streams";
import GameSettings from "./GameSettings";
import Iframe from "./Iframe";
import styles from "./Game.module.css";

const ITEMS_IN_ROW = 4;

type GameProps = {
    game: Game;
    index: number;
    length: number;
    isActive: boolean;
};

const GameComponent: FC<GameProps> = ({ game, index, length, isActive }) => {
    const { stop, setActive } = useGamesStore();
    const rows = Math.ceil(length / ITEMS_IN_ROW);
    const rowSize = Math.min(length, ITEMS_IN_ROW);
    const rowIndex = Math.floor(index / ITEMS_IN_ROW);
    const columnIndex = index % ITEMS_IN_ROW;

    return (
        <div
            className={clsx(styles.game, { [styles.isActive]: isActive })}
            style={
                isActive
                    ? {
                          height: `${100 - 20 * rows}vh`,
                          bottom: `${20 * rows}vh`,
                      }
                    : {
                          width: `${100 / rowSize}%`,
                          left: `${(100 * columnIndex) / rowSize}%`,
                          bottom: `${rowIndex * 20}vh`,
                      }
            }
        >
            <GameSettings
                game={game}
                isActive={isActive}
                toggleActive={() => {
                    if (isActive) {
                        setActive(null);
                    } else {
                        setActive(game.streamId);
                    }
                }}
                togglePlaying={() => stop(game.streamId)}
            />
            <Iframe id={game.streamId!} />
        </div>
    );
};

export default GameComponent;
